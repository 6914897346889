
// detect touch devices by registering the first touch
window.addEventListener('touchstart', function onFirstTouch() {
    $('body').addClass('js-has-touch');
    window.removeEventListener('touchstart', onFirstTouch, false);
}, false);


// initialize navi-toggle
$('.js-main-navi__toggle').on('click', function() {
    $('.main-navi__items').toggleClass('main-navi__items--mobile-visible');
});